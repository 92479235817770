import React, {Fragment, useState, useEffect, useContext} from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import TabbedList from '../components/wrappers/tabbed-list';
import CaseList from '../components/case-list';
import CaseListCarousel from "../components/case-list-carousel";

import Faq from '../components/faq';
import CardArticle from '../components/card-article';
import { graphql } from 'gatsby';
import Logos from "../components/logos";
import Cta from "../components/cta";
import TabbedServices from "../components/tabbed-services";
import { domain, formatRichText, isMobile, stripString } from "../utils";
import Markdown from "markdown-to-jsx"
import Media from "../components/media";
import Carousel from "../components/wrappers/carousel";
import Testimonial from "../components/testimonial";
import Link from "../components/link";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { CustomHr } from "../components/CustomHr";
import TriangleBubbles from "../components/hashTagsTriangles";

const ServiceNewDetailCasey = props => {
  const { general, footers, headers, serviceCeseyNew, servicesPage, theme } = props.data.strapi;
  const { serviceDetailFooterBgColorDarkMode, serviceDetailFooterBgColorLightMode, generalBackgroundColorDarkMode, generalBackgroundColorLightMode,  clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const { hero, bodyImages, articles, logos, cta1, cta2, useCases, keyFeatures, aboutUs, collage1, stats, copyright, author, seo, serviceEmail, main, casesSlider, casesHeading, faqs2, service_casey_new, testimonials, darkTheme, mobileHeroBackground, hashtagsSection } = serviceCeseyNew || {};
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const openingHours = (language ==="en") && servicesPage?.openingHours || ""; 
  const pageRedirectInfo = service_casey_new; 
  const redirectTo =  pageRedirectInfo?.language && pageRedirectInfo?.slug ? `/${pageRedirectInfo?.language}/services/${pageRedirectInfo?.slug}` : ""; 
  const breakpoints = useBreakpoint();
  const primaryBtn = general?.primaryBtn;
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = hero?.header && (hero.header.sup +" "+hero.header.heading);
  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading || "service detail casey ";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading || "service detail casey";
  const seoKeywords = seo && seo.metaData && seo.metaData?.keywords;
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  const isSubOpen = state.siteSubNav;
  
  const isMobileView = breakpoints.sm;
  const switchToDarkMode = theme.switchToDarkMode;
  const background = isMobileView && mobileHeroBackground ? mobileHeroBackground :  hero?.background && hero?.background;
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices;
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const servicesfooterColor = darkMode ? serviceDetailFooterBgColorDarkMode : serviceDetailFooterBgColorLightMode
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study); 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 

  const [backgroundImage, setBackgroundImage] = React.useState("");

  const [anchorLinks, setAnchorLinks] = useState([])
  const newAnchorLinks = [];
  footer && (footer.theme = 'short');

  if (copyright) {
    footer.copyright = copyright;
  }

  useEffect(() => {
   hero && hero.header.heading && newAnchorLinks.push({header: hero.header.anchorText, display: true}); 
   bodyImages && bodyImages.forEach((component) => {
     if (component.__typename === "STRAPI_ComponentComplexIntro") {
       component?.header?.anchorText && newAnchorLinks.push({header: component.header.anchorText, display: true});         
      } 
   })
   useCases && useCases.header && newAnchorLinks.push({header: useCases.header.anchorText , display: true}); 
   casesSlider &&  casesSlider.anchorText && newAnchorLinks.push({header: casesSlider.anchorText , display: true}); 
   keyFeatures &&  keyFeatures.header && newAnchorLinks.push({header: keyFeatures.header.anchorText, display: true}); 
   //  cta1.text.anchorText && newAnchorLinks.push({header: cta1.text.anchorText , display: true}); 
   main && main.forEach((component) => {
     if (component.__typename === "STRAPI_ComponentComplexCaptureGallery") {
       component &&  component.anchorText && newAnchorLinks.push({header: component.anchorText , display: true}); 
     } else if (component.__typename === "STRAPI_ComponentComplexFaqs") {
         component?.header?.anchorText && newAnchorLinks.push({header: component.header.anchorText, display: true});   
      }
   })
   logos?.header?.anchorText && newAnchorLinks.push({header: logos.header.anchorText , display: true}); 
   faqs2?.header?.anchorText && newAnchorLinks.push({header: faqs2.header.anchorText , display: true});
   testimonials?.anchorText && newAnchorLinks.push({header: testimonials.anchorText, display: true});  
   articles?.anchorText && newAnchorLinks.push({header: articles.anchorText , display: true}); 
   cta2?.anchorText && newAnchorLinks.push({header: cta2.anchorText , display: true}); 
   
   setAnchorLinks(newAnchorLinks);
    const id = "services"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
  }, []); 

  useEffect(()=> {
   if (isMobileView) {
    setBackgroundImage(background);
   } else {
    setBackgroundImage(background);
   }
  }, [background]);

  const imageExts = ['.svg', '.png', '.gif', '.jpg', '.jpeg'];
  const isImage = backgroundImage && imageExts.includes(backgroundImage.ext);
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  
  return (
    <Layout header={header} footer={footer} language={language} serviceEmail={serviceEmail} showWhiteIcon={false} redirectTo={redirectTo} anchorLinks={anchorLinks} isImage={!isImage} servicesfooterColor={servicesfooterColor} isServicesLandingPage={true} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="new-service-detail" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={darkTheme} 
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} serviceEmail={serviceEmail} showWhiteIcon={false} redirectTo={redirectTo} anchorLinks={anchorLinks} servicesfooterColor={servicesfooterColor}>
        {hero && (
          <section className="hero hero--white service-detail-design" style={{backgroundColor: hero.backgroundColor}} id={stripString(hero.header.anchorText ) || ''}>
            <div className="container-lg container align-items-end">
              <div className="hero__background hero__background--shadow">
                {backgroundImage && (
                  <div className="hero__background-inner">
                    <Media media={backgroundImage} showVideoAutoWith={true} />
                  </div>
                )}
              </div>
              <div className="hero__main">
                <div className="row align-items-end">
                  <div className="col-12">
                    <div className="hero__content">
                      <header className="hero__header header">
                        <h1 className="header__heading">
                          <small className="header__preheading">
                            {hero.header.supheading}
                          </small>
                          {hero.header.heading}
                        </h1>
                      </header>
                      
                      <div className="hero__body">
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                          {hero.body}
                        </Markdown>
                      </div>

                      {hero.links && (
                        <div className="hero__buttons buttons">
                          {hero.links.map((link) => {
                            return (
                              <>
                                {link.document && ( 
                                  <a href={`${link.document && link.document.url}`} className="button button--white" target="_blank">
                                    <span className="button__text">{link.label}</span>
                                  </a>
                                )}
                              </>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {bodyImages && bodyImages.map((component, index) => {
          if (component.__typename === "STRAPI_ComponentComplexIntro") {
            return (
              <section className="section intro" id={component?.header?.anchorText ? stripString(component.header.anchorText) : ''} style={{backgroundColor: sectionBackgroundColor}}>
                {component.header && (
                  <Header heading={component.header.heading} supheading={component.header.supheading} image={component.header.logo} className="header--pulledimage" />
                )}

                <div className="container">
                  <div className="row">
                    <div className="offset-tw-1 col-md-8 offset-lg-2 col-lg-6">
                      <div className="rich-text">
                         <Markdown 
                          options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}
                        >
                          {component.body}
                        </Markdown>
                      </div>
                      {component.buttons && component.buttons.length > 0 && (
                        <div className="buttons">
                          {component.buttons.map((button) => {
                            return (
                              <a href={`${button.document && button.document.url}`} className="button" target="_blank">
                                <span className="button__text">{button.label}</span>
                              </a>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )
          }
          if (component.__typename === "STRAPI_ComponentPlainMedia") {
            return (
              <section className="section media" style={{backgroundColor: sectionBackgroundColor}}>
                <Media media={component.media} width={component.width} shouldNotLazyload={true}/>
              </section>
            )
          }
        })}
        {useCases && (
          <TabbedServices 
            component={useCases} 
            language={language} 
            shouldNotLazyLoad={true} 
            imageBorderRadiusForServices={imageBorderRadiusForServices} 
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
          />
        )}

        <section className="section cases" id={stripString(casesHeading.anchorText) || ''} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-10 random-heading">
                  {casesSlider.heading && (
                    <Header  
                      heading={casesSlider.heading} 
                    />)}
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-lg-10 offset-lg-1">
                  <CaseList
                    caseStudies={casesSlider.caseStudies} 
                    oddElementMarginTop={true}  
                    showCaseIntroText={true}
                    imageBorderRadiusForServices={imageBorderRadiusForServices}
                    websiteFontColor={websiteFontColor}
                    hashtagsBackgroundColor={displayedHashtagBgColor}
                    pageTransitionBgColor={pageTransitionBgColor}
                  />
                </div>
              </div>
            </div>
        </section>
        {keyFeatures && (
          <TabbedServices 
            component={keyFeatures} 
            language={language} 
            shouldNotLazyLoad={true} 
            imageBorderRadiusForServices={imageBorderRadiusForServices}
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
          />
        )}

        {cta1 && (
          <Cta 
            component={cta1} 
            className="cta--wide"
            showNumber={true}  
            openingHour={openingHours} 
            author={author}
            showTeaser={true} 
            shouldNotLazyLoad={true}      
            greySectionBgColor={greySectionBgColor} 
            fontColorLightMode={websiteFontColor}    
          />
        )}
        <section className="case-detail" style={{backgroundColor: sectionBackgroundColor}}>
          {main && main.map((component, index) => {
            if (component.__typename === "STRAPI_ComponentPlainMedia") {

              return (
                <div className="case-detail__content">
                  <Media media={component.media} width={component.width} positionVideoCenter={true} shouldNotLazyload={true}/>
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexHorizontalBodyImage") {
              const rows = component.horizontalBodyImage;

              return rows.map((row, index) => {

                const isOdd = index % 2;
                const bodySide = row.bodySide || (isOdd ? 'right' : 'left');

                const body = (
                  <div className="col-md-4 case-detail__body">
                    <Markdown options={{
                      overrides: {a: {
                        component: Link
                      },                           
                      hr: { 
                          component: CustomHr,
                          props: {
                            borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                          }
                        }  
                      }
                    }}>
                      {formatRichText(row.richText)}
                    </Markdown>
                  </div>
                )

                const media = (
                  <div className="col-md-8 case-detail__media-gapped">
                    {row.media && row.media.length > 0 && (
                      <Fragment>
                        {row.media && row.media.length > 1 ? (
                          <Carousel>
                            {row.media.map((media, index) => {
                              return <img className="image--fluid" src={media.url} alt={media.alternativeText} />
                            })}
                          </Carousel>
                        ) : (
                          <img className="image--fluid" src={row.media[0].url} alt={row.media[0].alternativeText}  />
                        )}
                      </Fragment>
                    )}
                  </div>
                )

                return (
                  <div className={`case-detail__content case-detail__content--${bodySide}`}>
                    <div className="container">
                      <div className="row align-items-center">
                        {bodySide === 'left' ? body : media}
                        {bodySide === 'right' ? body : media}
                      </div>
                    </div>
                  </div>
                )
              });
            }

            if (component.__typename === "STRAPI_ComponentComplexCaptureGallery") {
              const medias = component.medias;

              return (
                <div className={`case-detail__content ${component.anchorText ? 'anchor-text' : ''}`} id={stripString(component.anchorText) || ''}>
                  <div className="container">
                    {component.heading && (
                      <div className="case-detail__header row">
                        <div className="col-md-5 offset-md-1">
                          <h2 className="case-detail__heading">{component.heading}</h2>
                        </div>
                      </div>
                    )}
                    {component.richText && (
                      <div className="case-detail__body row">
                        <div className="col-md-7 offset-md-2">
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {formatRichText(component.richText)}
                          </Markdown>
                        </div>
                      </div>
                    )}
                    {medias && medias.length > 0 && (
                      <div className="case-detail__gallery row">
                        {medias.map((media, index) => {
                          return <Media 
                            media={media.media} 
                            width={media.width} 
                            columns={media.columnsOutOf12} 
                            className="case-detail__image" 
                            captionPosition={component.captionPosition} 
                            withCaption={true} 
                            shouldNotLazyload={true}
                          />
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexVerticalBodyImage") {
              const columns = component.verticalBodyImage;
              const colSize = columns.length === 1 ? "col-md-8 offset-md-2" : "col-md";
              
              return (
                <div className="case-detail__content">
                  <div className="container">
                    <div className="row">
                      {columns.map((column, index) => {
                        return (
                          <div className={colSize}>
                            {column.media && column.media.length > 0 && (
                              <Fragment>
                                {column.media.length > 1 ? (
                                  <Carousel >
                                    {column.media.map((media, index) => {
                                      return <img className="case-detail__image image--fluid" src={media.url} alt={media.alternativeText} />
                                    })}
                                  </Carousel>
                                ) : (
                                  <img className="case-detail__image image--fluid"
                                   src={column.media[0].url} 
                                   alt={column.media[0].alternativeText}  
                                   />
                                )}
                              </Fragment>
                            )}
                            <div className={`case-detail__body`}>
                              <Markdown 
                                options={{
                                  overrides: {a: {
                                    component: Link
                                  },                           
                                  hr: { 
                                      component: CustomHr,
                                      props: {
                                        borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                      }
                                    }  
                                  }
                                }}
                              >
                                {formatRichText(column.richText)}
                              </Markdown>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexBodyLink") {
              return (<Cta component={component} openingHour={openingHours} shouldNotLazyLoad={true} />)
            }

            if (component.__typename === "STRAPI_ComponentComplexTestimonials" && component.testimonials && component.testimonials?.length && component.showTestimonials) {
              return (
                <div className="case-detail__content">
                  <div className="container">
                    <Testimonial 
                      testimonialBtnText={ primaryBtn }
                      testimonials={component.testimonials} 
                      shouldNotLazyLoad={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      logosBgColor={logosBgColor}
                      sectionBackgroundColor={sectionBackgroundColor}
                    />
                  </div>
                </div>
              )
            }
            if (component.__typename === "STRAPI_ComponentComplexFaqs") {
              return (
                <>
                    {(component && component.faqs.length > 0) && (
                        <section 
                          className="section faqs section--grey" 
                          id={stripString(component.header.anchorText) ||''}
                          style={{backgroundColor: greySectionBgColor}}
                        >
                          {component.header && (
                            <Header heading={component.header.heading} supheading={component.header.supheading} />
                          )}
                          <div className="container">
                          <div className="row">
                            <div className="offset-tw-1 col-md-8 offset-lg-2 col-lg-6 rich-text">
                              {component.body && (
                                <Markdown 
                                  options={{
                                    overrides: {a: {
                                      component: Link
                                    },                           
                                    hr: { 
                                        component: CustomHr,
                                        props: {
                                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                        }
                                      }  
                                    }
                                  }}
                                >
                                 {component.body}
                                </Markdown>)}
                            </div>
                          </div>
                          </div>
                          {component.faqs && component.faqs.length > 0 && (
                            <div className="container">
                              <div className="row">
                                {component.faqs.map((faq) => {
                                  return (
                                    <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2">
                                      <Faq 
                                        heading={faq.heading} 
                                        shouldNotLazyLoad={true} 
                                        showWhitePlus={darkTheme || switchToDarkMode || darkMode}     
                                        fontColorLightMode={fontColorLightMode}                                 
                                      >
                                        <Markdown options={{
                                          overrides: {a: {
                                            component: Link
                                          },                           
                                          hr: { 
                                              component: CustomHr,
                                              props: {
                                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                              }
                                            }  
                                          }
                                        }}>
                                          {faq.richText}
                                        </Markdown>
                                      </Faq>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                      </section>
                   )}
               </>
              )
            }

            return <></>
          })}
        </section>
  
        {logos && (
          <Logos component={logos} shouldNotLazyLoad={true} sectionBackgroundColor={sectionBackgroundColor} logosBgColor={logosBgColor}/>
        )}
        {faqs2 &&  faqs2.faqs.length > 0 && (
          <section className="section faqs section--grey" id={ faqs2.header.anchorText && stripString(faqs2.header.anchorText) || ''} style={{backgroundColor: greySectionBgColor}}>
            {faqs2.header && (
              <Header heading={faqs2.header.heading} supheading={faqs2.header.supheading} />
            )}
           <div className="container">
             <div className="row">
               <div className="offset-tw-1 col-md-8 offset-lg-2 col-lg-6 rich-text">
                 {faqs2.body && (
                    <Markdown options={{
                      overrides: {a: {
                        component: Link
                      },                           
                      hr: { 
                          component: CustomHr,
                          props: {
                            borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                          }
                        }  
                      }
                    }}>
                  {faqs2.body}</Markdown>)}
               </div>
              </div>
            </div>
            {faqs2.faqs && (
              <div className="container">
                <div className="row">
                  {faqs2.faqs.map((faq) => {
                    return (
                      <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2">
                        <Faq heading={faq.heading} shouldNotLazyLoad={true} showWhitePlus={darkTheme || switchToDarkMode || darkMode} fontColorLightMode={fontColorLightMode}>
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>{faq.richText}
                          </Markdown>
                        </Faq>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </section>
        )}
        {(testimonials && testimonials?.showTestimonials && testimonials.testimonials?.length) && (
          <section className="section" id={stripString(testimonials.anchorText)} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <Testimonial 
                testimonialBtnText={primaryBtn}
                testimonials={testimonials.testimonials} 
                shouldNotLazyLoad={true}
                title={testimonials.title}
                imageBorderRadiusForServices={imageBorderRadiusForServices}
                logosBgColor={logosBgColor}
                sectionBackgroundColor={sectionBackgroundColor}
              />
            </div>
          </section>
        )}
        {(articles && filteredRelatedCards.length > 0) && (
          <section className="cards-articles section" id={articles.anchorText && stripString(articles.anchorText) || ''} style={{backgroundColor: sectionBackgroundColor}}>
            <Header heading={articles.heading} supheading={articles.sup} withFullWidth={true}/>

            <div className="container">
              <div className="row card-articles">
                {filteredRelatedCards.map((relatedCard, index) => {
                  const article = relatedCard.blog || relatedCard.case_study;

                  return (
                    <CardArticle 
                      article={article} 
                      path={relatedCard.blog ? 'blog' : 'case-studies'}                       
                      showMobileImage={relatedCard.blog === 'blog'} 
                      shouldNotLazyLoad={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices} 
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                    />)
                })}
              </div>
            </div>
          </section>
        )}
        {hashtagsSection && (
          <section className="section">
            <TriangleBubbles  
              hashtagsBackgroundColor={hashtagsBackgroundColor} 
              borderRadius={imageBorderRadiusForServices}
              hashtagsSection={hashtagsSection}
            />
          </section>
        )}
        {cta2 && (
          <Cta 
            className="cta--wide" 
            component={cta2} 
            showNumber={true} 
            showTeaser={true} 
            author={author}  
            openingHour={openingHours} 
            anchorId={cta2.anchorText}
            shouldNotLazyLoad={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServiceDetailNewConfiguration($id: ID!, $language: String!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        serviceDetailFooterBgColorDarkMode
        serviceDetailFooterBgColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      servicesPage {
        openingHours
      }
      theme {
        switchToDarkMode
      }
      serviceCeseyNew(id: $id) {
        darkTheme
        author {
          name
          picture {
            url
          }
          jobTitle
        }
        hashtagsSection {
          heading
          tags {
            tag
          }
        }
        mobileHeroBackground {
          url
          alternativeText
          mime
          caption
          ext
        }
        hero {
          background {
            url
            alternativeText
            mime
            caption
            ext
          }
          header {
            supheading
            heading
            anchorText
          }
          body
          links {
            label
            path
            document {
              url
            }
          }
        }
        serviceEmail
         seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        service_casey_new {
          language
          slug
        }
        faqs2 {
          header {
            heading
            supheading
            anchorText
          }
          faqs {
            heading
            richText
          }
          body
          media {
            url
            alternativeText
            mime
            ext
          }
        }
        casesSlider {
          heading
          anchorText
          caseStudies {
            ...CardArticleCaseStudy
          }
        }
        casesHeading {
          heading
          supHeading
          anchorText
        }
        bodyImages {
          ... on STRAPI_ComponentPlainMedia {
            __typename
            media {
              url
              ext
              mime
              alternativeText
            }
            width
            iframeUrl
          }
          ... on STRAPI_ComponentComplexIntro {
            __typename
            header {
              anchorText
              supheading
              heading
              logo {
                url
                alternativeText
                mime
                ext
              }
              anchorText
            }
            body
            buttons {
              path
              label
              document {
                url
              }
            }
          }
        }
        main {
          ... on STRAPI_ComponentComplexFaqs { 
            __typename
            header {
              heading
              supheading
              anchorText
            }
            faqs {
              heading
              richText
            }
            body
          }
          ... on STRAPI_ComponentPlainMedia {
            __typename
            media {
              url
              ext
              mime
              alternativeText
            }
            width
          }
          ... on STRAPI_ComponentComplexHorizontalBodyImage {
            __typename
            horizontalBodyImage {
              media {
                url
                alternativeText
                mime
                ext
              }
              richText
              bodySide
            }
          }
          ... on STRAPI_ComponentComplexCaptureGallery {
            __typename
            anchorText
            medias {
              media {
                url
                alternativeText
                mime
                caption
                ext
              }
              width
              columnsOutOf12
            }
            heading
            richText
            captionPosition
          }
          ... on STRAPI_ComponentComplexVerticalBodyImage {
            __typename
            verticalBodyImage {
              media {
                url
                alternativeText
                mime
                ext
              }
              richText
            }
          }
          ... on STRAPI_ComponentComplexBodyLink {
            __typename
            text {
              heading
              description
            }
            link {
              label
              path
            }
          }
          ... on STRAPI_ComponentComplexTestimonials {
            __typename
          testimonials {
            quote
            position
            name
            company
            description
            media {
              url
              ext
              mime
              alternativeText
            }
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
          }
        }
        cta1 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        logos {
          header {
            supheading
            heading
            anchorText
          }
          logos {
            url
            alternativeText
            mime
            ext
          }
          affix
        }
        useCases {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        keyFeatures {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        aboutUs {
          header {
            supheading
            heading
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
          body
          buttons {
            path
            label
              document {
                url
              }
          }
        }
        collage1 {
          url
          alternativeText
          mime
          ext
        }
        stats {
          header {
            supheading
            heading
          }
          stat {
            value
            label
          }
        }
        testimonials {
          title
          showTestimonials
          anchorText
          testimonials {
            media {
              url
              alternativeText
              mime
              ext
            }
            logo {
              url
              alternativeText
              mime
              ext
            }
            quote
            description
            name
            position
            company
          }
        }
        articles {
          sup
          heading
          relatedCard {
            blog {
              ...CardArticleBlog
            }
            case_study {
              ...CardArticleCaseStudy
            }
          }
        }
        cta2 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
          anchorText
        }
        copyright
      }
      caseStudies(where: { language: $language }, sort: "date:desc", limit: 4) {
        language
        headerImage {
          url
        }
        clientLogo {
          logo {
            url
          }
        }
        title
        slug
        tags {
          tag
        }
        description
        backgroundColor
      }
    }
    thumbrow: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 239, height: 239, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    category: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 655, height: 369, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default ServiceNewDetailCasey;
